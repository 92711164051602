import React from 'react';
import Header from './Header';
import Footer from './Footer'
import Navigation from './Navigation';
import Aboutme from './Aboutme';
import Project from './Project';
import Request from './Request';
import CleaningServices from './CleaningServices';
import {Routes,Route} from 'react-router-dom'







function Main(){
    return(  
    <React.Fragment>
        <Navigation/>
        
        <Header/>
        <Routes>
        <Route path="/AnitaMaidSite/" element= {<CleaningServices/>}/>
            <Route path="/portfolio" element= {<Project/>}/>
            <Route path="/aboutme" element= {<Aboutme/>}/>
            
        <Route path="/request" element= {<Request/>}/>
        </Routes>
        <Footer/>
        

    </React.Fragment>
    )
}

export default Main

