import React, { useState } from "react";
// Here we are importing a CSS file as a dependency
import emailjs from "emailjs-com";



function Request() {

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    time: "",
    comments: "",
    submitted: false
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, phone, email, address, time, comments } = formData;

    const templateParams = {
      from_name: name,
      from_phone: phone,
      from_email: email,
      from_address: address,
      from_time: time,
      from_comment: comments
    };

    emailjs.send('service_c5853ve', 'template_nk5ikcm', templateParams, 'KscC8SCUUXEUm4EXs')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

    console.log("Form Data: ", formData);
  
    setFormData((prevData) => ({
      ...prevData,
      name: "",
      phone: "",
      email: "",
      address: "",
      time: "",
      comments: "",
      submitted: true,
    }));
  
    setTimeout(() => {
      setFormData((prevData) => ({
        ...prevData,
        submitted: false,
      }));
    }, 10000);
  
  
    
//     event.preventDefault();
//     const { name, phone, email, address, time, comments } = formData;
//     const subject = "Cleaning Request";
//     const body = `Name: ${name}
// Phone: ${phone}
// Email: ${email}
// Address: ${address}
// Time Request: ${time}
// Special Comment: ${comments}`;
//     const mailtoLink = `mailto:info@anitamaid.com?subject=${subject}&body=${body}`;
//     window.location.href = mailtoLink;
//     // send email with form data
//     console.log("Form Data: ", formData);
  };

  return (


<div >
<div className="flex mb-10 opacity-75 justify-center">
      <img
        alt="Instagram"
        src="https://scontent-atl3-2.xx.fbcdn.net/v/t1.6435-9/49784199_284667822235972_507044503022993408_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=AGgrxCIH0KYAX9YRGWY&_nc_ht=scontent-atl3-2.xx&oh=00_AfAhlaJ2QLKXcvWOdKOlEccCr0f3_ermNJsMLoIy3HGupg&oe=648273CA"
        className="sm:w-1/6 h-5/12  rounded-full mx-auto"
      />
    </div>
            <h1><span className='bg-[#0369A1] opacity-75'>GET A QUOTE</span></h1>
    <div className="bg-[#0369A1] border-8 border-gray-500 rounded-md mt-0 sm:mt-10 m-auto h-[33rem] sm:h-1/2 sm:w-2/6 w-1/2">
    <form className="flex flex-col justify-center items-center h-[%] sm:h-1/2" onSubmit={handleSubmit}>
      <div>
        <label className="font-sans text-white text-lg mt-5 text-center block" htmlFor="name">Name:</label>
        <br></br>
        <input className=""
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="font-sans text-white text-lg text-center block" htmlFor="phone">Phone Number:</label>
        <br></br>
        <input
          type="text"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="font-sans text-white text-lg text-center block" htmlFor="email">Email:</label>
        <br></br>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="font-sans text-white text-lg text-center block" htmlFor="address">Address:</label>
        <br></br>
        <input
          type="text"
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="font-sans text-white text-lg text-center block" htmlFor="time">Time Request:</label>
        <br></br>
        <input
          type="text"
          id="time"
          name="time"
          value={formData.time}
          onChange={handleChange}
        />
      </div>

      <div>
        <label className="text-base text-white text-center sm:block mt-0 sm:mt-4" htmlFor="comments">Special Comments:</label>
        <br></br>
        <textarea
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleChange}
        />
      </div>

      
      <h1  className='m-0 sm:m-5 animate-pulse'><span className='bg-black'><button type="submit">{formData.submitted ? "Submitted" : "Submit"}</button></span></h1>
    </form>
    </div>
           
        </div>
  )}



  export default Request;