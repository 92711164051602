import React, { useState,useEffect } from 'react';
import {Link} from 'react-router-dom'



function CleaningServices() {

    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
      setIsVisible(true);
    }, []);
    return (
      
      <div  className="mt-30 mb-10 justify-center items-center">
         <div className="flex mb-10 opacity-75 justify-center">
      <img
        alt="Instagram"
        src="https://scontent-atl3-2.xx.fbcdn.net/v/t1.6435-9/49784199_284667822235972_507044503022993408_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=AGgrxCIH0KYAX9YRGWY&_nc_ht=scontent-atl3-2.xx&oh=00_AfAhlaJ2QLKXcvWOdKOlEccCr0f3_ermNJsMLoIy3HGupg&oe=648273CA"
        className="sm:w-1/6 h-5/12  rounded-full mx-auto"
      />
    </div>
        {/* <div id="header" className="section bg-transparent ">
               <img alt="logo" className="img-circle animate-[wiggle_1s_ease-in-out_infinite]" src={Profile}/>  
          </div> */}
      <h1 id="aboutme" ><span className='bg-[#0369A1]'>Cleaning Services</span></h1>
      
      <div className='bg-[#0369A1] border-8 border-gray-500 rounded-md mt-10 mx-auto sm:w-8/12'>
  <div className='text-white font-bold p-8' id="aboutmewords">
    <p className='text-[1rem] break-words text-left text-white'>
      <br />
      AnitaMaid is proud to provide cleaning services to metro Atlanta and surrounding areas!
      <br /><br />
      Services we provide:
      <br />
      - One-time, Weekly, and Monthly housekeeping services
      <br />
      - Move In/Out Cleans
      <br />
      - Organization and declutter
      <br />
      - Carpet, rug, and upholstery cleaning
      <br /><br />
      We also serve Airbnb and VRBO communities!
      <br /><br />
      When you trust us to handle your vacation rental, it will be cleaned till it's spotless every time! You can even automate your cleanings so that you can sit back and relax while we handle your rental.
      <br /><br />
      In addition to cleaning, we also provide laundry service, upholstery cleaning, restocking, and co-hosting services for your rental!
      <br /><br />
      Our Airbnb and VRBO vacation rental cleaning service is all-inclusive. We will leave your rental in immaculate condition, ensuring a five-star review from your next guest!
      <br /><br />
      Our Airbnb and VRBO vacation rental cleaning service is all-inclusive.
      <br /><br />
      We will leave your rental in immaculate condition, ensuring a five-star review from your next guest!
      <br></br><br></br><Link className="active" to='/request'>Click Here to Request Cleaning</Link>
    </p>
  </div>
</div>


      </div>
    );
}
    
export default CleaningServices